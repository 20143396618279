<form class="form grid-12 compliance-requirement-form" #complianceRequirementForm="ngForm">
    <div class="g-col-12">
        <esa-material-ff name="Name" [editMode]="editMode" [required]="true" type="text" [(ngModel)]="model.Name" [customLabel]="nameCustomLabel">
        </esa-material-ff>
        <ng-template #nameCustomLabel>
            <custom-form-label label="Name" fieldDefinitionType="Name"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="ComplianceLead"
            [editMode]="editMode"
            [required]="false"
            type="dropdown"
            [options]="users$ | async"
            optionLabel="FullName"
            optionValue="UserID"
            [(ngModel)]="model.ComplianceLeadUserID"
            [customLabel]="leadCustomLabel">
        </esa-material-ff>
        <ng-template #leadCustomLabel>
            <custom-form-label label="Compliance Lead" fieldDefinitionType="ComplianceLead"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Scope"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="scopes$ | async"
            optionLabel="Name"
            optionValue="ScopeID"
            [(ngModel)]="model.ScopeID"
            [customLabel]="scopeCustomLabel">
        </esa-material-ff>
        <ng-template #scopeCustomLabel>
            <custom-form-label label="Scope" fieldDefinitionType="Scope"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Phase"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="phases$ | async"
            optionLabel="Name"
            optionValue="PhaseID"
            [(ngModel)]="model.PhaseID"
            [customLabel]="phaseCustomLabel">
        </esa-material-ff>
        <ng-template #phaseCustomLabel>
            <custom-form-label label="Phase" fieldDefinitionType="Phase"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Frequency"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="frequencies$ | async"
            optionLabel="Name"
            optionValue="FrequencyID"
            [(ngModel)]="model.FrequencyID"
            [customLabel]="frequencyCustomLabel">
        </esa-material-ff>
        <ng-template #frequencyCustomLabel>
            <custom-form-label label="Frequency" fieldDefinitionType="Frequency"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="ImplementationResponsibilities"
            [editMode]="editMode"
            [required]="false"
            [dropdownMultiple]="true"
            type="dropdown"
            [options]="implementationResponsibilities$ | async"
            optionLabel="Name"
            optionValue="ImplementationResponsibilityID"
            [(ngModel)]="model.ImplementationResponsibilityIDs"
            [customLabel]="implementationResponsibilitiesCustomLabel">
        </esa-material-ff>
        <ng-template #implementationResponsibilitiesCustomLabel>
            <custom-form-label label="Implementation Responsibilities" fieldDefinitionType="ImplementationResponsibilities"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="ComplianceRequirementTypes"
            [editMode]="editMode"
            [required]="true"
            [dropdownMultiple]="false"
            type="dropdown"
            [options]="complianceRequirementTypes$ | async"
            optionLabel="Name"
            optionValue="ComplianceRequirementTypeID"
            [(ngModel)]="model.ComplianceRequirementTypeID"
            [customLabel]="complianceRequirementTypeCustomLabel">
        </esa-material-ff>
        <ng-template #complianceRequirementTypeCustomLabel>
            <custom-form-label label="Compliance Requirement Type" fieldDefinitionType="ComplianceRequirementType"></custom-form-label>
        </ng-template>

        <div class="kv-pair">
            <custom-form-label label="Tags" fieldDefinitionType="Tags"></custom-form-label>
            <mat-form-field class="value" *ngIf="editMode">
                <mat-chip-grid #chipList aria-label="Tag selection">
                    <mat-chip-row *ngFor="let tag of selectedComplianceRequirementTags" [removable]="removable" (removed)="removeTag(tag)">
                        {{ tag.Name }}
                        <button matChipRemove [attr.aria-label]="'remove ' + tag" *ngIf="removable">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input
                        placeholder="Add tag..."
                        #tagInput
                        [matChipInputFor]="chipList"
                        [matAutocomplete]="auto"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [formControl]="tagControl"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addTag($event)" />
                </mat-chip-grid>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                    <mat-option *ngFor="let tag of filteredComplianceRequirementTags | async" [value]="tag">
                        {{ tag.Name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <div class="value-display middle-form-spacing" *ngIf="!editMode && selectedComplianceRequirementTags.length <= 0">
                <span>-</span>
            </div>

            <div
                class="value-display middle-form-spacing-chips"
                *ngIf="!editMode && selectedComplianceRequirementTags.length > 0"
                style="padding: 1rem 0 1rem 0">
                <mat-chip-row *ngFor="let tag of selectedComplianceRequirementTags" style="margin-right: 8px">
                    {{ tag.Name }}
                </mat-chip-row>
            </div>
        </div>

        <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
            <custom-form-label label="Applicable Commitment Text" fieldDefinitionType="ApplicableCommitmentText"></custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12" style="margin-bottom: 1rem">
                    <div class="g-col-12">
                        <tinymce-editor
                            #tinyMceEditor
                            name="ApplicableCommitmentText"
                            [(content)]="model.ApplicableCommitmentText"
                            [isRequired]="true"></tinymce-editor>
                    </div>
                </div>
                <button
                    mat-flat-button
                    *ngIf="!isFirefox() && editMode"
                    color="primary"
                    type="button"
                    label="Select from Full Text"
                    icon="border_color"
                    (click)="openSelectFromFullTextDialog()">
                    <mat-icon>border_color</mat-icon> Select from Full Text
                </button>
            </div>

            <div class="value-display" *ngIf="!editMode">
                <div [innerHTML]="model.ApplicableCommitmentText | bypassHtml"></div>
            </div>
        </div>
    </div>

    <div class="g-col-12 actions-bar" style="margin-top: 1rem" *ngIf="!hasComplianceReq">
        <span [matTooltip]="!complianceRequirementForm.valid ? 'You are missing requried fields.' : ''">
            <button
                mat-flat-button
                style="margin-right: 1rem"
                color="primary"
                (click)="saveForm(complianceRequirementForm)"
                [disabled]="!complianceRequirementForm.valid">
                <mat-icon>save</mat-icon> Save
            </button>
        </span>

        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>

<mat-action-row *ngIf="hasComplianceReq" style="margin-top: 1rem">
    <div *ngIf="!hideButton">
        <button mat-icon-button type="button" *ngIf="hasEditBtn && !editMode" color="primary" (click)="editForm()"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button type="button" *ngIf="hasDeleteBtn && !editMode" color="primary" (click)="deleteForm()"><mat-icon>delete</mat-icon></button>
    </div>
    <div class="flex-end gap">
        <span [matTooltip]="!complianceRequirementForm.valid ? 'You are missing requried fields.' : ''">
            <button mat-flat-button *ngIf="editMode" color="primary" (click)="updateForm()" [disabled]="!complianceRequirementForm.valid">
                <mat-icon>save</mat-icon> Save
            </button>
        </span>
    
        <button mat-button type="button" *ngIf="editMode" color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
    
</mat-action-row>
