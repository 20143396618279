<div class="main">
    <div class="page-header pb-3">
        <h1 class="page-title">New Source Document</h1>
        <div class="copy copy-2">
            <p>This page allows you to create a new Source Document.</p>
        </div>
    </div>

    <source-document-form
        #createNewSourceDocumentForm
        [inputSource]="model"
        [projectName]="projectName"
        [editMode]="true"
        (formSubmitted)="save($event)"
        [showFileUpload]="true"
        (cancelEditModeChange)="cancelEdit()">
    </source-document-form>
</div>
