import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf, DatePipe } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { routeParams } from "src/app/app.routes";
import { INavItem } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";

@Component({
    selector: "source-document-details",
    templateUrl: "./source-document-details.component.html",
    styleUrls: ["./source-document-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, MatTooltip, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, SidebarNavComponent, DatePipe],
})
export class SourceDocumentDetailsComponent implements OnInit, OnDestroy {
    public sourceDocument$: Observable<SourceDto>;
    public source?: SourceDto | undefined;
    public currentUser: UserDto;
    public editMode: boolean;
    sourceSideNav: INavItem[];

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        private sourceService: SourceService,
        private currentSourceDocumentService: CurrentSourceDocumentService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {
        this.sourceSideNav = [
            {
                Title: "Summary Information",
                RouterLink: "summary-information",
            },
            {
                Title: "Related Commitments",
                RouterLink: "related-commitments",
            },
        ];
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            let sourceID = params[routeParams.sourceDocumentID];
            this.source = null;
            this.currentSourceDocumentService.setCurrentSourceDocument(null);

            this.sourceService.sourcesSourceIDGet(sourceID).subscribe((result) => {
                this.source = result;
                this.currentSourceDocumentService.setCurrentSourceDocument(this.source);
                this.cdr.markForCheck();
            });
        });

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
            this.editMode = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    refreshCommitment(result) {
        this.source = result;
        this.cdr.markForCheck();
        this.currentSourceDocumentService.setCurrentSourceDocument(this.source);
    }

    canDelete() {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.SourceDocumentRights, RightsEnum.Delete);
    }

    delete() {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Source Document`,
                text: `Are you sure you want to delete this Source Document? This action cannot be undone.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.deleteSubscription = this.sourceService.sourcesSourceIDDelete(this.source.SourceID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The Source Doccument was successfully deleted.", AlertContext.Success), 5000);
                    this.router.navigate(["/commitment-library/source-documents"]);
                });
            });
    }
}
