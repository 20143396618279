<mat-dialog-content>
    <div class="credits" style="width: fit-content; margin-left: auto; margin-right: auto">
        <a href="https://esassoc.com/" class="esa-logo" alt="ESA" target="_blank">
            <img style="height: 10rem" src="assets/main/logos/esa-logo.svg" />
        </a>
    </div>

    <div class="copyright" style="width: fit-content; margin: 0 auto 0 auto">
        Designed, built, and managed by <a href="http://esassoc.com/" target="_blank">ESA</a> for <a href="http://water.ca.gov/" target="_blank">DWR</a>
        {{ currentYear }}
    </div>

    <div class="copyright" style="width: fit-content; margin: 0 auto 0 auto" *ngIf="currentTenant$ | async as currentTenant">
        Tenant Name: {{ currentTenant.Name }}
    </div>

    <div class="g-col-12 version" style="width: fit-content; margin: 0 auto 0 auto" *ngIf="systemInfoDto$ | async as systemInfoDto">
        Version: {{ systemInfoDto.Version }} - {{ systemInfoDto.Environment }}&nbsp;&nbsp;|&nbsp;&nbsp;Built on:
        {{ systemInfoDto.CompilationDateTime | date: "short" }}&nbsp;&nbsp;|&nbsp;&nbsp;Copyright &copy; {{ currentYear }} State of California
    </div>
</mat-dialog-content>
