import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { combineLatest, switchMap, of, tap, Observable } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { INavGroup, INavItem, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { SidebarNavComponent } from "src/app/shared/components/sidebar-nav/sidebar-nav.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PillComponent } from "../../shared/components/pill/pill.component";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";

@Component({
    selector: "project-details-layout",
    standalone: true,
    imports: [RouterOutlet, SidebarNavComponent, LayoutSidebarNavComponent, CommonModule, PillComponent],
    templateUrl: "./project-details-layout.component.html",
    styleUrl: "./project-details-layout.component.scss",
})
export class ProjectDetailsLayoutComponent implements OnDestroy {
    ngOnDestroy(): void {
        this.projectUpdatedService.setCurrentProject(null);
    }

    private projectUpdatedService: CurrentProjectService = inject(CurrentProjectService);
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private projectService: ProjectService = inject(ProjectService);
    public sourceDocumentCount: number;
    public componentCount: number;
    public commitmentCount: number;
    public evidenceOfComplianceCount: number;
    public projectSideNav: INavGroup[] = [];

    project$ = combineLatest([this.activatedRoute.paramMap, this.projectUpdatedService.getCurrentProject()]).pipe(
        switchMap(([paramMap, updatedProject]) => {
            let projectChange = paramMap.get(routeParams.projectID) != updatedProject?.ProjectID;
            if (updatedProject && !projectChange) {
                return of(updatedProject);
            }
            return this.projectService.projectsProjectIDGet(paramMap.get(routeParams.projectID)).pipe(
                tap((x) => {
                    this.projectUpdatedService.setCurrentProject(x);
                })
            );
        }),
        tap((project) => {
            this.projectSideNav = [
                {
                    Items: [
                        {
                            Title: "Summary Information",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Related Source Documents",
                            RouterLink: "related-source-documents",
                            Icon: "SourceDocument",
                            ItemCount: project.SourceDocumentCount,
                        },
                        {
                            Title: "Related Commitments",
                            RouterLink: "related-commitments",
                            Icon: "Commitment",
                            ItemCount: project.CommitmentCount,
                        },
                    ],
                },
                {
                    Title: "Compliance Tracking",
                    Items: [
                        {
                            Title: "Evidence Of Compliance",
                            RouterLink: "evidence-of-compliance",
                            Icon: "EvidenceOfCompliance",
                            ItemCount: project.EvidenceOfComplianceCount,
                        },
                        {
                            Title: "Components",
                            RouterLink: "components",
                            Icon: "Component",
                            ItemCount: project.ComponentCount,
                        },
                    ],
                },
            ];
        })
    );
}
