import { Component, inject, Input, OnInit } from "@angular/core";
import { ComponentSummaryDto } from "../../generated/model/component-summary-dto";
import { MatIcon } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { PillComponent } from "../pill/pill.component";
import { DatePipe } from "@angular/common";
import { PillComponentStatusComponent } from "../pill-component-status/pill-component-status.component";
import { MatTooltip } from "@angular/material/tooltip";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { ComponentService } from "../../generated/api/component.service";
import { FileService } from "src/app/services/file/file.service";
import { MatButtonModule } from "@angular/material/button";
import { LoadingButtonDirective } from "../../directives/loading-button/loading-button.directive";

@Component({
    selector: "component-summary",
    standalone: true,
    imports: [
        MatIcon,
        RouterModule,
        PillComponent,
        DatePipe,
        PillComponentStatusComponent,
        MatTooltip,
        BeaconEntityIconComponent,
        MatButtonModule,
        LoadingButtonDirective,
    ],
    templateUrl: "./component-summary.component.html",
    styleUrl: "./component-summary.component.scss",
})
export class ComponentSummaryComponent implements OnInit {
    private componentService: ComponentService = inject(ComponentService);
    private fileService: FileService = inject(FileService);
    public isLoadingOne: boolean = false;
    public isLoadingTwo: boolean = false;
    public hasFiles: boolean = false;
    @Input() component: ComponentSummaryDto;

    ngOnInit(): void {
        this.componentService.componentsComponentIDFileCheckGet(this.component.ComponentID).subscribe((response) => {
            if (response == true) {
                this.hasFiles = true;
            }
        });
    }

    downloadCoversheetWithFiles() {
        // todo: add new loading spinner when merged in
        this.isLoadingOne = true;
        this.componentService.componentsComponentIDCoverSheetWithFilesGet(this.component.ComponentID).subscribe((response) => {
            this.isLoadingOne = false;
            this.fileService.handleResponse(response, `Component-Coversheet_${this.component.ComponentID}.zip`);
        });
    }

    downloadCoversheet() {
        this.isLoadingTwo = true;
        this.componentService.componentsComponentIDCoverSheetGet(this.component.ComponentID).subscribe((response) => {
            this.isLoadingTwo = false;
            this.fileService.handleResponse(response, `Component-Coversheet_${this.component.ComponentID}.pdf`);
        });
    }
}
