<div class="evidence-header">
    <h3>Evidence of Compliance</h3>
    <button
        [disabled]="!hasEvidence"
        mat-icon-button
        matTooltip="Download All Evidence Of Compliance Files As .zip"
        (click)="downloadZipForAllFilesForChecklistItem()">
        <mat-icon>file_save</mat-icon>
    </button>
</div>
<div class="upsert-new-container">
    @if (existingEvidencesOnChecklistItem$ | async; as existingEvidenceOnChecklistItem) { @if (existingEvidenceOnChecklistItem.length > 0) {

    <div class="existing-evidences">
        <ul>
            <li *ngFor="let evidence of existingEvidenceOnChecklistItem">
                <div class="evidence-item">
                    <div class="evidence-item__header flex-between">
                        <a
                            [routerLink]="[
                                '/compliance-tracking/projects',
                                projectID,
                                'components',
                                _componentID,
                                'evidence-of-compliance',
                                evidence.EvidenceOfComplianceID
                            ]">
                            <strong>{{ evidence.Title }}</strong>
                        </a>
                        @if (evidence.EvidenceOfComplianceChecklistItems.length > 1) {
                        <mat-icon
                            [inline]="true"
                            matTooltip="This Evidence is associated with {{ evidence.EvidenceOfComplianceChecklistItems.length - 1 }} other Checklist items."
                            >hub</mat-icon
                        >
                        }
                    </div>
                    <div class="evidence-item__body">
                        <kv-pair key="Notes" [value]="evidence.Notes"></kv-pair>
                        <kv-pair key="Files" [value]="filesTemplate"></kv-pair>
                        <ng-template #filesTemplate>
                            @if (evidence.EvidenceOfComplianceFiles.length > 0) {
                            <ul>
                                <li *ngFor="let file of evidence.EvidenceOfComplianceFiles">
                                    <simple-file-display
                                        [fileName]="file.Name"
                                        [displayDelete]="false"
                                        [downloadUrl]="'evidence-of-compliance-file/' + file.EvidenceOfComplianceFileID"></simple-file-display>
                                </li>
                            </ul>
                            } @else {
                            <em>None</em>
                            }
                        </ng-template>
                    </div>
                    <div class="flex-end evidence-item__footer">
                        <button
                            mat-icon-button
                            color="primary"
                            matTooltip="{{ 'Download All Files For ' + evidence.Title + ' As .zip' }}"
                            (click)="downloadZipForSingleEvidence(evidence.EvidenceOfComplianceID, evidence.Title)">
                            <mat-icon>download</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" (click)="editEvidence(evidence)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button color="primary" (click)="deleteEvidence(evidence)"><mat-icon>delete</mat-icon></button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    } @else {
    <div class="no-evidence-container">
        <div class="no-evidence-text">No Evidence</div>
    </div>
    }

    <div class="add-evidence-button-container">
        <button mat-flat-button color="primary" type="button" (click)="addEvidence()" class="btn btn-primary"><mat-icon>add</mat-icon>Add New Evidence</button>
        @if (componentExistingEvidence$ | async; as availableOptions) {
        <button
            mat-flat-button
            color="primary"
            type="button"
            (click)="addExistingEvidence(availableOptions)"
            class="btn btn-primary"
            [disabled]="availableOptions.length < 1">
            Add Existing Evidence
        </button>
        }
    </div>
    } @else {
    <loading-spinner></loading-spinner>
    }
</div>
