import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { FileService } from "src/app/services/file/file.service";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { SourceFileService } from "src/app/shared/generated/api/source-file.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { FileSizeBytesToStringService } from "src/app/shared/services/file-size-bytes-to-string/file-size-bytes-to-string.service";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { ConfirmService } from "src/app/services/confirm.service";
import { EsaLabelComponent } from "esa-material-form-field";

@Component({
    selector: "source-document-file-list",
    templateUrl: "./source-document-file-list.component.html",
    styleUrls: ["./source-document-file-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, AgGridAngular, BackToTopComponent, AsyncPipe, GridActionsComponent, EsaLabelComponent],
})
export class SourceDocumentFileListComponent implements OnInit, OnDestroy {
    @ViewChild("sourceDocumentFileGrid") sourceDocumentFileGrid: AgGridAngular;

    public sourceDocument$: Observable<any>;
    public sourceDocument: SourceDto;
    public sourceDocumentFiles$: Observable<any>;
    public currentUser: UserDto;
    public rowData: any[];
    public columnDefs: ColDef[];

    user: Subscription;
    fileDeleteSubscription: Subscription;
    fileDownloadSubscription: Subscription;

    constructor(
        private sourceFileService: SourceFileService,
        private fileService: FileService,
        private currentSourceDocumentService: CurrentSourceDocumentService,
        private dateColumnCreator: DateColumnCreatorService,
        private fileSizeBytesToStringService: FileSizeBytesToStringService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.buildGrid();
            this.cdr.markForCheck();
        });

        this.sourceDocument$ = this.currentSourceDocumentService.getCurrentSourceDocument().pipe(
            map((data) => {
                this.sourceDocument = data;
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.fileDeleteSubscription?.unsubscribe();
        this.fileDownloadSubscription?.unsubscribe();
    }

    buildGrid() {
        this.columnDefs = [];

        this.columnDefs.push(
            {
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: {
                    onClick: this.downloadFile.bind(this),
                    icon: "download",
                },
                cellStyle: (params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 60,
                colId: "download",
            },
            {
                headerName: "Name",
                field: "Name",
                tooltipField: "Name",
                flex: 4,
                sort: "asc",
            },
            {
                headerName: "Size",
                field: "$Size",
                flex: 1,
            },
            {
                headerName: "Uploaded By",
                field: "$UploadedBy",
                flex: 1.5,
            },
            this.dateColumnCreator.createDateColumnDef("Uploaded On", "$UploadedOn", "M/dd/YYYY", 1.3)
        );

        if (this.canDeleteSourceDocumentFile(this.currentUser)) {
            this.columnDefs.push({
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: {
                    onClick: this.deleteFile.bind(this),
                    icon: "delete",
                    type: "clear",
                },
                cellStyle: (params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 90,
                colId: "delete",
            });
        }
    }

    onSourceDocumentFileGridReady(gridEvent: any) {
        this.sourceDocumentFileGrid.api.showLoadingOverlay();
        this.refreshData();
        this.sourceDocumentFileGrid.api.hideOverlay();
    }

    refreshData() {
        if (this.sourceDocument) {
            this.sourceDocumentFiles$ = this.sourceFileService.sourcesSourceIDFilesGet(this.sourceDocument.SourceID).pipe(
                map((results) => {
                    this.rowData = results;
                    this.rowData.forEach((rd) => {
                        rd.$UploadedBy = rd?.UpdateUser?.FullName ?? rd.CreateUser.FullName;
                        rd.$UploadedOn = rd.UpdateDate ?? rd.CreateDate;
                        rd.$Size = this.fileSizeBytesToStringService.formatBytes(rd.ObjectSize);
                    });
                    return results;
                })
            );
        }
    }

    canUploadSourceDocumentFile(currentUser: UserDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.SourceDocumentRights, RightsEnum.Create);
    }

    canDeleteSourceDocumentFile(currentUser: UserDto): boolean {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.SourceDocumentRights, RightsEnum.Delete);
    }

    fileUploadSuccess(filesUploaded: any) {
        this.refreshData();
        this.alertService.pushAlert(new Alert(`The file${filesUploaded.length > 1 ? "s were" : " was"} successfully uploaded.`, AlertContext.Success), 5000);
    }

    downloadFile($event: any) {
        this.fileDownloadSubscription = this.sourceFileService
            .sourcesSourceIDFilesSourceFileIDDownloadGet(this.sourceDocument.SourceID, $event.rowData.SourceFileID)
            .subscribe((result) => {
                if (!result) {
                    return;
                }
                const file = new File([result], $event.rowData.Name);
                if (navigator.msSaveBlob) {
                    return navigator.msSaveBlob(file, file.name);
                }
                this.downloadAsHref(URL.createObjectURL(file), file.name);
            });
    }

    downloadAsHref(href: string, filename: string) {
        const tempDownloadLink = document.createElement("a");
        tempDownloadLink.href = href;
        tempDownloadLink.download = filename;
        document.body.appendChild(tempDownloadLink);
        tempDownloadLink.click();
        document.body.removeChild(tempDownloadLink);
    }

    deleteFile($event: any) {
        this.confirmService
            .confirm({ color: "warn", header: `Delete File`, text: `Are you sure you want to delete this file? This action can not be undone.` })
            .subscribe((result) => {
                if (!result) return;
                this.fileDeleteSubscription = this.sourceFileService
                    .sourcesSourceIDFilesSourceFileIDDelete(this.sourceDocument.SourceID, $event.rowData.SourceFileID)
                    .subscribe((result) => {
                        this.alertService.pushAlert(new Alert("The file was succesfully deleted.", AlertContext.Success), 5000);
                        this.refreshData();
                        this.cdr.detectChanges();
                    });
            });
    }
}
