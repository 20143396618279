import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { CommitmentUpsertDto } from "src/app/shared/generated/model/models";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CommitmentFormComponent } from "../commitment-form/commitment-form.component";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { NgForm } from "@angular/forms";
import { CommitmentFileService } from "src/app/shared/generated/api/commitment-file.service";
import { Subscription } from "rxjs";
import { arrForEach } from "@microsoft/applicationinsights-core-js";
import { CommitmentFileDto } from "src/app/shared/generated/model/commitment-file-dto";

@Component({
    selector: "commitment-create",
    templateUrl: "./commitment-create.component.html",
    styleUrls: ["./commitment-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommitmentFormComponent],
})
export class CommitmentCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createNewCommitmentForm", { read: CommitmentFormComponent }) form: CommitmentFormComponent;

    public commitment: CommitmentUpsertDto = new CommitmentUpsertDto();
    public commitmentUpsertDto: CommitmentUpsertDto;
    private commitmentResponse: any;

    commitmentFileSubscription: Subscription;

    editMode = true;

    constructor(
        private commitmentService: CommitmentService,
        private currentCommitmentService: CurrentCommitmentService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private commitmentFileService: CommitmentFileService
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.SourceID) {
                this.commitment.SourceID = params.SourceID;
            }

            this.commitmentUpsertDto = this.currentCommitmentService.createCommitmentDto(this.commitment);
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
    }

    save(form: NgForm): void {
        this.commitmentService.commitmentsPost(this.form.commitmentUpsertDto).subscribe(
            (response) => {
                this.commitmentResponse = response;

                if (this.form.files && this.form.files.length > 0) {
                    this.commitmentFileSubscription = this.commitmentFileService
                        .commitmentsCommitmentIDFilesPost(this.commitmentResponse.CommitmentID, this.form.files)
                        .subscribe({
                            next: (response) => {
                                this.router
                                    .navigateByUrl(`/commitment-library/commitments/${this.commitmentResponse.CommitmentID}/summary-information`)
                                    .then(() => {
                                        this.alertService.pushAlert(new Alert("The commitment was successfully created.", AlertContext.Success), 5000);
                                    });
                            },
                            error: (error) => {},
                            complete: () => {},
                        });
                } else {
                    this.router.navigateByUrl(`/commitment-library/commitments/${this.commitmentResponse.CommitmentID}/summary-information`).then(() => {
                        this.alertService.pushAlert(new Alert("The commitment was successfully created.", AlertContext.Success), 5000);
                    });
                }
            },
            (error) => {
                this.cdr.detectChanges();
            }
        );
    }

    cancelEdit() {
        this.router.navigateByUrl("/commitment-library/commitments");
    }

    canExit() {
        if (this.commitmentResponse) {
            return true;
        } else {
            return JSON.stringify(this.commitmentUpsertDto) === JSON.stringify(this.form.commitmentUpsertDto);
        }
    }
}
