<h1 mat-dialog-title>Select Checklist Items To Associate</h1>
<div mat-dialog-content class="dialog-wrapper">
    @if (checklistItems$ | async; as checklistItems) { @if(checklistItems.length > 0) { @if (filterOptions$ | async; as filterOptions) {
    <div class="filter-wrapper">
        <h2>Filter(s)</h2>
        <div [formGroup]="filterFormGroup">
            <div class="filters">
                <mat-form-field class="search-box" subscriptSizing="dynamic">
                    <mat-label>Search</mat-label>
                    <input matInput formControlName="search" placeholder="Search..." />
                    @if (filterFormGroup.get("search").value) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('search').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Phase</mat-label>
                    <mat-select formControlName="phase" [multiple]="true">
                        <mat-option *ngFor="let phase of filterOptions.phases" [value]="phase.PhaseID">{{ phase.Name }}</mat-option>
                    </mat-select>
                    @if (filterFormGroup.get("phase").value.length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('phase').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Scope</mat-label>
                    <mat-select formControlName="scope" [multiple]="true">
                        <mat-option *ngFor="let scope of filterOptions.scopes" [value]="scope.ScopeID">{{ scope.Name }}</mat-option>
                    </mat-select>
                    @if (filterFormGroup.get("scope").value.length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('scope').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Frequency</mat-label>
                    <mat-select formControlName="frequency" [multiple]="true">
                        <mat-option *ngFor="let frequency of filterOptions.frequencies" [value]="frequency.FrequencyID">{{ frequency.Name }}</mat-option>
                    </mat-select>
                    @if (filterFormGroup.get("frequency").value.length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('frequency').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Compliance Requirement Type</mat-label>
                    <mat-select formControlName="complianceRequirementType" [multiple]="true">
                        <mat-option
                            *ngFor="let complianceRequirementType of filterOptions.complianceRequirementTypes"
                            [value]="complianceRequirementType.ComplianceRequirementTypeID"
                            >{{ complianceRequirementType.Name }}</mat-option
                        >
                    </mat-select>
                    @if (filterFormGroup.get("complianceRequirementType").value.length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('complianceRequirementType').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Resource Category</mat-label>
                    <mat-select formControlName="resourceCategory" [multiple]="true">
                        <mat-option *ngFor="let resourceCategory of filterOptions.resourceCategories" [value]="resourceCategory.ResourceCategoryID">{{
                            resourceCategory.Name
                        }}</mat-option>
                    </mat-select>
                    @if (filterFormGroup.get("resourceCategory").value.length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('resourceCategory').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" [multiple]="true">
                        <mat-option *ngFor="let status of filterOptions.statuses" [value]="status.ChecklistItemStatusID">{{ status.Name }}</mat-option>
                    </mat-select>
                    @if (filterFormGroup.get("status").value.length > 0) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('status').reset()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>
                @if (filterActive) {
                <button mat-button color="primary" (click)="filterFormGroup.reset()">Clear Filters</button>
                }
            </div>
        </div>
    </div>

    <form [formGroup]="formGroup">
        @for(checklistItem of filteredChecklistItems$ | async; track $index) {
        <div>
            <mat-checkbox
                [checked]="checklistItem.ChecklistItemID | objectInArray : selectedChecklistItemIDs"
                (change)="checklistItemSelectionChanged($event, checklistItem.ChecklistItemID)">
                {{ checklistItem.ComplianceRequirement.CommitmentClientCommitmentID }} - {{ checklistItem.ComplianceRequirement.Name }}
            </mat-checkbox>
        </div>
        }
    </form>
    } } @else {
    <strong>No Checklist Items Available To Relate</strong>
    } } @else {
    <beacon-loading-overlay>
        <loading-spinner color="accent" [diameter]="50" />
    </beacon-loading-overlay>
    }
</div>
<div class="dialog-actions-wrapper" mat-dialog-actions>
    <div>
        <button mat-flat-button color="primary" type="button" (click)="clearSelection()">Clear Selection</button>
        <button mat-flat-button color="primary" type="button" (click)="selectAll()">Select All</button>
    </div>
    <div>
        @if(selectedChecklistItemIDs.length > 0) {
        {{ selectedChecklistItemIDs.length }} Item{{ selectedChecklistItemIDs.length > 1 ? "s" : "" }} Selected }
        <button mat-flat-button color="primary" (click)="onSave()" cdkFocusInitial>Save</button>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
