import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { TenantDto } from "src/app/shared/generated/model/tenant-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";

@Component({
    selector: "source-document-list",
    templateUrl: "./source-document-list.component.html",
    styleUrls: ["./source-document-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, AgGridAngular, MatButton, MatIcon, GridActionsComponent],
})
export class SourceDocumentListComponent implements OnInit, OnDestroy {
    @ViewChild("sourceDocumentsGrid") sourceDocumentsGrid: AgGridAngular;

    public currentUser: UserDto;
    public sourceDocuments: SourceDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    user: Subscription;
    getSourceDocumentRequest: Subscription;

    constructor(
        private sourceDocumentService: SourceService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Source Document Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.SourceID,
                        LinkDisplay: params.data.Name,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/source-documents/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                sort: "asc",
            },
            this.dateColumnCreator.createDateColumnDef("Date of Latest Amendment", "DateOfLatestAmendment", "M/dd/YYYY"),
            {
                headerName: "Reference Number",
                field: "ReferenceNumber",
                flex: 1,
                type: "rightAligned",
            },
            {
                headerName: "Agency",
                field: "ApprovingAgency",
                flex: 1,
                type: "rightAligned",
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getSourceDocumentRequest?.unsubscribe();
    }

    navigateToCreateSourceDocumentPage() {
        this.router.navigate(["/commitment-library/source-documents/create"]);
    }

    onSourceDocumentsGridReady(gridEvent) {
        this.sourceDocumentsGrid.api.showLoadingOverlay();

        this.getSourceDocumentRequest = this.sourceDocumentService.sourcesGet().subscribe((results) => {
            this.rowData = results;

            this.sourceDocuments = results;

            let projectIDs = this.sourceDocuments.map((s) => s.Project.ProjectID);
            let distinctProjectIDs = projectIDs.reduce((unique, projectID) => {
                return unique.includes(projectID) ? unique : [...unique, projectID];
            }, []);

            if (distinctProjectIDs.length > 1) {
                //Insert project column after the title column.
                let indexOfTitleColumn = this.columnDefs.findIndex((cd) => cd.headerName === "Name");
                this.columnDefs.splice(indexOfTitleColumn + 1, 0, {
                    headerName: "Project",
                    headerTooltip: "Project",
                    valueGetter: function (params: any) {
                        return {
                            LinkValue: params.data.Project.ProjectID,
                            LinkDisplay: params.data.Project.Name,
                        };
                    },
                    cellRenderer: LinkRendererComponent,
                    cellRendererParams: { inRouterLink: "/commitment-library/projects/" },
                    filter: CustomDropdownFilterComponent,
                    filterParams: {
                        columnContainsMultipleValues: false,
                    },
                    filterValueGetter: function (params: any) {
                        return params.data.Project.Name;
                    },
                    comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                        let valueA = linkA.LinkDisplay.toLowerCase();
                        let valueB = linkB.LinkDisplay.toLowerCase();

                        return valueA.localeCompare(valueB, undefined, {
                            numeric: true,
                            sensitivity: "base",
                        });
                    },
                    flex: 3,
                });

                this.sourceDocumentsGrid.api.setColumnDefs(this.columnDefs);
            }

            this.sourceDocumentsGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    get canCreateSourceDocument(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
    }
}
