import { Injectable } from "@angular/core";
import { FileService } from "./file.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";

@Injectable({
    providedIn: "root",
})
export class FileDownloadService {
    private fileDownloadSubscription: Subscription;

    constructor(
        private fileService: FileService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private http: HttpClient,
        private evidenceOfComplianceFileService: EvidenceOfComplianceFileService
    ) {}

    downloadFile(evidenceOfComplianceID: string, evidenceOfComplianceTitle: string, onComplete: () => void) {
        this.fileDownloadSubscription = this.evidenceOfComplianceFileService
            .evidenceOfComplianceFileEvidenceOfComplianceIDAllFilesDownloadGet(evidenceOfComplianceID)
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.fileService.handleResponse(response, `EvidenceOfCompliance_${evidenceOfComplianceTitle}_${evidenceOfComplianceID}.zip`);
                    }
                },
                error: (e) => {
                    this.alertService.pushAlert(new Alert("Error zipping files.", AlertContext.Danger));
                    onComplete();
                },
                complete: () => {
                    onComplete();
                },
            });
    }

    downloadFilteredFilesForComponent(componentID: string, evidenceOfComplianceIDs: string[], onComplete: () => void) {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0].replace(/-/g, "_");
        const evidenceOfComplianceIDsString = evidenceOfComplianceIDs.join(",");
        this.fileDownloadSubscription = this.evidenceOfComplianceFileService
            .evidenceOfComplianceFileComponentComponentIDAllFilesDownloadGet(componentID, evidenceOfComplianceIDsString)
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.fileService.handleResponse(response, `EvidenceOfCompliance_${componentID}_${formattedDate}.zip`);
                    }
                },
                error: (e) => {
                    this.alertService.pushAlert(new Alert("Error zipping files.", AlertContext.Danger));
                    onComplete();
                },
                complete: () => {
                    onComplete();
                },
            });
    }

    downloadAllFilesForChecklistItem(checklistItemID: string, onComplete: () => void) {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0].replace(/-/g, "_");
        this.fileDownloadSubscription = this.evidenceOfComplianceFileService
            .evidenceOfComplianceFileChecklistItemChecklistItemIDAllFilesDownloadGet(checklistItemID)
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.fileService.handleResponse(response, `EvidenceOfCompliance_ChecklistItem_${checklistItemID}_${formattedDate}.zip`);
                    }
                },
                error: (e) => {
                    this.alertService.pushAlert(new Alert("Error zipping files.", AlertContext.Danger));
                    onComplete();
                },
                complete: () => {
                    onComplete();
                },
            });
    }
}
