<div class="page-header">
    <div>
        <ng-container *ngIf="templateAbove" [ngTemplateOutlet]="templateAbove"></ng-container>
        <h3 *ngIf="preTitle">{{ preTitle }}</h3>
        <div [ngClass]="templateRight ? 'flex-between no-wrap' : ''">
            <h1 class="page-title">
                <span>
                    <mat-icon class="page-header-icon" *ngIf="icon">{{ icon }}</mat-icon>
                    {{ pageTitleDisplay }}
                </span>

                <ng-container *ngIf="templateTitleAppend" [ngTemplateOutlet]="templateTitleAppend"></ng-container>
            </h1>
            <div *ngIf="templateRight" class="template-right">
                <ng-container [ngTemplateOutlet]="templateRight"></ng-container>
            </div>
        </div>
        <ng-content></ng-content>
        <hr />
    </div>
</div>
