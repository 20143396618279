import { Injectable } from "@angular/core";
import { ComplianceRequirementUpsertDto } from "../shared/generated/model/compliance-requirement-upsert-dto";
import { ComplianceRequirementDto } from "../shared/generated/model/compliance-requirement-dto";

@Injectable({
    providedIn: "root",
})
export class CurrentComplianceRequirementService {
    constructor() {}

    createComplianceRequirementDto(complianceRequirement: ComplianceRequirementDto) {
        const complianceRequirementDto = new ComplianceRequirementUpsertDto({
            Name: complianceRequirement?.Name,
            ComplianceLeadUserID: complianceRequirement?.ComplianceLeadUser?.UserID,
            ScopeID: complianceRequirement?.Scope?.ScopeID,
            PhaseID: complianceRequirement?.Phase?.PhaseID,
            FrequencyID: complianceRequirement?.Frequency?.FrequencyID,
            ApplicableCommitmentText: complianceRequirement?.ApplicableCommitmentText,
            Tags: complianceRequirement?.Tags,
            ImplementationResponsibilityIDs: complianceRequirement?.ImplementationResponsibilities?.map((ir) => ir.ImplementationResponsibilityID) ?? [],
            ComplianceRequirementTypeID: complianceRequirement?.ComplianceRequirementType?.ComplianceRequirementTypeID,
        });

        return complianceRequirementDto;
    }
}
