import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Observable, Subscription, tap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { CommonModule } from "@angular/common";
import { KvPairComponent } from "../../shared/components/kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../../shared/components/simple-file-display/simple-file-display.component";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import {
    ProjectUpsertDialogComponent,
    IProjectUpsertDialogComponentResponse,
    IProjectUpsertDialogComponentData,
} from "src/app/shared/components/dialogs/project-upsert-dialog/project-upsert-dialog.component";
import { SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { TinymceEditorComponent } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { BypassHtmlPipe } from "../../shared/pipes/bypass-html/bypass-html.pipe";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";

@Component({
    selector: "project-details",
    templateUrl: "./project-details.component.html",
    styleUrls: ["./project-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButtonModule,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        LoadingSpinnerComponent,
        CommonModule,
        KvPairComponent,
        SimpleFileDisplayComponent,
        RouterLink,
        PageHeaderComponent,
        PageBodyComponent,
        MatIcon,
        MatIconButton,
        LoadingButtonDirective,
        SidebarNavComponent,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        BypassHtmlPipe,
        BeaconEntityIconComponent,
    ],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
    public project$: Observable<ProjectDto>;
    public projectID: string;
    public componentID: string;
    public currentUser: UserDto;
    public downloading: boolean = false;

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;
    fileDownloadSubscription: Subscription;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog,
        private currentProjectService: CurrentProjectService
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.project$ = this.currentProjectService.getCurrentProject().pipe(
            tap((project) => {
                this.projectID = project?.ProjectID;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }

    editProject(project: ProjectDto) {
        this.dialog.open<ProjectUpsertDialogComponent, IProjectUpsertDialogComponentData, IProjectUpsertDialogComponentResponse>(ProjectUpsertDialogComponent, {
            width: "1000px",
            data: {
                Project: project,
            },
        });
    }

    canDelete(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
    }

    delete() {
        this.confirmService
            .confirm({ color: "warn", header: `Delete Project`, text: `Are you sure you want to delete this Project? This action cannot be undone.` })
            .subscribe((result) => {
                if (!result) return;
                this.deleteSubscription = this.projectService.projectsProjectIDDelete(this.projectID).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The Project was successfully deleted.", AlertContext.Success), 5000);
                    this.router.navigate(["/compliance-tracking/projects"]);
                });
            });
    }
}
