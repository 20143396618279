<div class="detail-view" *ngIf="commitment$ | async">
    <div class="actions-bar">
        <button mat-icon-button *ngIf="canEdit()" type="button" color="primary" (click)="enableEditMode()"><mat-icon>edit</mat-icon></button>
    </div>

    <form class="form grid-12" (ngSubmit)="save()" #updateCommitmentForm="ngForm">
        <div class="g-col-12">
            <esa-material-ff name="Version" [editMode]="false" type="text" [(ngModel)]="commitment.VersionNumber" [customLabel]="versionCustomLabel">
            </esa-material-ff>
            <ng-template #versionCustomLabel>
                <custom-form-label label="Version Number" fieldDefinitionType="VersionNumber"></custom-form-label>
            </ng-template>

            <esa-material-ff name="DateApproved" [editMode]="false" type="date" [(ngModel)]="commitment.ApprovedDate" [customLabel]="approvedDateCustomLabel">
            </esa-material-ff>
            <ng-template #approvedDateCustomLabel>
                <custom-form-label label="Date Approved" fieldDefinitionType="DateApproved"></custom-form-label>
            </ng-template>

            @if (commitment.ApprovedDate) {
                <esa-material-ff
                    name="ApprovedUser"
                    [editMode]="false"
                    [required]="false"
                    type="dropdown"
                    [options]="users$ | async"
                    optionLabel="FullName"
                    optionValue="UserID"
                    [(ngModel)]="commitment.ApprovedUser.UserID"
                    [customLabel]="approvedByCustomLabel">
                </esa-material-ff>
                <ng-template #approvedByCustomLabel>
                    <custom-form-label label="Approved By" fieldDefinitionType="ApprovedBy"></custom-form-label>
                </ng-template>
            }

            @if (commitment.VersionNumber > 1) {
                <esa-material-ff
                    name="OriginatorOfChange"
                    [editMode]="editMode"
                    [required]="true"
                    type="text"
                    placeholder="Originator of Change"
                    [(ngModel)]="commitmentUpsertDto.OriginatorOfChange"
                    [customLabel]="changeCustomLabel">
                </esa-material-ff>
                <ng-template #changeCustomLabel>
                    <custom-form-label label="Originator of Change" fieldDefinitionType="OriginatorOfChange"></custom-form-label>
                </ng-template>

                <div class="g-col-12">
                    <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
                        <custom-form-label label="Summary of Change" fieldDefinitionType="SummaryOfChange"></custom-form-label>

                        <div class="value" *ngIf="editMode">
                            <div class="grid-12">
                                <div class="g-col-12">
                                    <tinymce-editor
                                        #tinyMceEditor
                                        name="SummaryOfChange"
                                        [(content)]="commitmentUpsertDto.SummaryOfChange"
                                        [isRequired]="true"></tinymce-editor>
                                </div>
                            </div>
                        </div>
                        <div
                            class="value-display"
                            *ngIf="!editMode && commitmentUpsertDto.SummaryOfChange"
                            [innerHTML]="commitmentUpsertDto.SummaryOfChange | bypassHtml"></div>
                        <div class="value-display" *ngIf="!editMode && !commitmentUpsertDto.SummaryOfChange">-</div>
                    </div>
                </div>
            }
        </div>

        <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
            <span [matTooltip]="!form.valid ? 'You are missing requried fields.' : ''">
                <button mat-flat-button style="margin-right: 1rem" color="primary" (click)="save()" [disabled]="!form.valid">
                    <mat-icon>save</mat-icon> Save
                </button>
            </span>
            <button mat-button type="button" color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
        </div>
    </form>
    <beacon-back-to-top></beacon-back-to-top>
</div>
