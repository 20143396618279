<page-body>
    @if (commitment$ | async; as commitment) {
        <page-header [preTitle]="commitment.Title" [pageTitle]="'Compliance Requirements'"> </page-header>
        <div class="compliance-requirement-builder">
            <div class="compliance-requirement-builder__commitment">
                <div class="full-commitment-text" [innerHTML]="commitment.FullCommitmentText"></div>
            </div>
            <div class="compliance-requirement-builder__compliance-requirements" [class.is-reordering]="isReordering" cdkDropListGroup>
                
                

                @if (complianceRequirements$ | async; as complianceRequirements) {
                    <div class="compliance-requirement-builder__compliance-requirements__actions">
                        @if(isReordering) {
                            <button color="primary" mat-flat-button (click)="saveSortOrder(complianceRequirements)"><mat-icon>save</mat-icon> Save</button>
                            <button color="accent" mat-flat-button (click)="cancelSortOrder()"><mat-icon>cancel</mat-icon> Cancel</button>
                        } @else {
                            <button color="accent" mat-flat-button (click)="editSortOrder()"><mat-icon>reorder</mat-icon>Reorder</button>
                        }
                    </div>
                    @if (complianceRequirements.length <= 0 && !creatingNewComplianceRequirement) {
                        <empty-list>
                            <p>No compliance requirements have been added to this commitment yet.</p>
                            <p>Click the button below to add a compliance requirement.</p>
                            <button mat-flat-button color="accent" (click)="createComplianceRequirement(null)" type="button">
                                <mat-icon>add</mat-icon>Add Compliance Requirement
                            </button>
                        </empty-list>
                    } @else if(complianceRequirements.length > 0) {
                        @for(phase of phases$ | async; track $index) { 
                            <div class="mb-4">
                                <h2>{{ phase.Name }}</h2>
                            
                                @if(complianceRequirements | filter: "Phase.PhaseID": phase.PhaseID; as complianceRequirementsInPhase) {
                                    <div class="drop-box" [class.is-reordering]="isReordering" cdkDropList (cdkDropListDropped)="drop($event, phase, complianceRequirements)" [cdkDropListData]="complianceRequirementsInPhase" [cdkDropListDisabled]="!isReordering">
                                        @if(complianceRequirementsInPhase.length > 0) {
                                            @for (complianceRequirement of complianceRequirementsInPhase; track $index) {
                                                
                                                <compliance-requirement-builder-bar
                                                    cdkDrag
                                                    [cdkDragData]="complianceRequirement"
                                                    (clickedDelete)="deleteComplianceRequirement($event)"
                                                    (clickedEdit)="editingComplianceRequirement($event)"
                                                    (canceledEdit)="canceledEdit($event)"
                                                    (editFormSubmitted)="editFormSubmitted($event)"
                                                    [disableButtons]="isEditingComplianceRequirement || creatingNewComplianceRequirement || isReordering"
                                                    [complianceRequirement]="complianceRequirement" >
                                                    <div class="drop-placeholder" *cdkDragPlaceholder></div>
                                                </compliance-requirement-builder-bar>
                                            }
                                            @if(!creatingNewComplianceRequirement && !isReordering) {
                                                <button mat-button color="accent" (click)="createComplianceRequirement(phase)" type="button" [disabled]="isEditingComplianceRequirement" >
                                                    <mat-icon>add</mat-icon>Add Compliance Requirement
                                                </button>
                                            }
                                        } @else {
                                            @if(!isReordering){
                                                <empty-list [showIcon]="false">
                                                    <p>Click the button below to add a compliance requirement to the <strong>{{phase.Name}}</strong> Phase.</p>
                                                    <button mat-flat-button color="accent" (click)="createComplianceRequirement(phase)" type="button" [disabled]="creatingNewComplianceRequirement || isEditingComplianceRequirement">
                                                        <mat-icon>add</mat-icon>Add Compliance Requirement
                                                    </button>
                                                </empty-list>
                                            }
                                            
                                        }
                                        @if(creatingNewComplianceRequirement && creatingWithPhaseID === phase.PhaseID) {
                                            <ng-container [ngTemplateOutlet]="newComplianceRequirementStepper">

                                            </ng-container>
                                        }
                                    </div>
                                } 

                            

                            </div>
                        }
                    }

                    <ng-template #newComplianceRequirementStepper>
                            <div class="">
                                <hr />
                                <mat-stepper orientation="vertical" [linear]="true" #stepper>
                                    <mat-step [stepControl]="firstFormGroup">
                                        <form [formGroup]="firstFormGroup">
                                            <ng-template matStepLabel>
                                                Compliance Requirement Name
                                                @if (firstFormGroup.get("Name").valid) {
                                                    <span>: {{ firstFormGroup.get("Name").value }}</span>
                                                }
                                            </ng-template>
                                            <mat-form-field>
                                                <mat-label>Compliance Requirement Name</mat-label>
                                                <input matInput placeholder="Compliance Requirement Name" formControlName="Name" required />
                                            </mat-form-field>
                                            <div class="flex-end">
                                                <button mat-flat-button color="accent" matStepperNext>Next</button>
                                            </div>
                                        </form>
                                    </mat-step>
                                    <mat-step [stepControl]="secondFormGroup">
                                        <form [formGroup]="secondFormGroup">
                                            <ng-template matStepLabel>Phase</ng-template>
                                            <mat-button-toggle-group
                                                [hideSingleSelectionIndicator]="true"
                                                [formControl]="secondFormGroup.controls.Phase"
                                                aria-label="Phase">
                                                @for (phase of phases$ | async; track $index) {
                                                    <mat-button-toggle [value]="phase.PhaseID">{{ phase.Name }}</mat-button-toggle>
                                                }
                                            </mat-button-toggle-group>
                                            <div class="flex-between">
                                                <button mat-flat-button color="accent" matStepperPrevious>Back</button>
                                                <button mat-flat-button color="accent" matStepperNext>Next</button>
                                            </div>
                                        </form>
                                    </mat-step>
                                    <mat-step [stepControl]="thirdFormGroup">
                                        <form [formGroup]="thirdFormGroup">
                                            <ng-template matStepLabel>Applicable Commitment Text</ng-template>
                                            <editor
                                                class="mb-2"
                                                #tinyMceEditor
                                                [init]="tinyMceEditor | tinyMceConfig"
                                                (onChange)="cdr.markForCheck()"
                                                [formControl]="thirdFormGroup.controls.ApplicableCommitmentText"></editor>
                                            <div class="flex-between">
                                                <button mat-flat-button color="accent" matStepperPrevious>Back</button>
                                                <button mat-flat-button color="accent" matStepperNext>Next</button>
                                            </div>
                                        </form>
                                    </mat-step>
                                    <mat-step [stepControl]="fourthFormGroup">
                                        <form [formGroup]="fourthFormGroup">
                                            <ng-template matStepLabel>Compliance Requirement Type</ng-template>
                                            <mat-form-field>
                                                <mat-label>Compliance Requirement Type</mat-label>
                                                <mat-select [formControl]="fourthFormGroup.controls.ComplianceRequirementType" required>
                                                    <mat-option>--</mat-option>
                                                    @for (type of crTypes$ | async; track $index) {
                                                        <mat-option [value]="type.ComplianceRequirementTypeID">{{ type.Name }}</mat-option>
                                                    }
                                                </mat-select>
                                            </mat-form-field>
                                            <div class="flex-between">
                                                <button mat-flat-button color="accent" matStepperPrevious>Back</button>
                                            </div>
                                        </form>
                                    </mat-step>
                                </mat-stepper>
                                <hr />
                                <div class="flex-end gap">
                                    <button
                                        mat-flat-button
                                        color="primary"
                                        [loadingButton]="isLoadingSubmit"
                                        [disabled]="!firstFormGroup.valid || !secondFormGroup.valid || !thirdFormGroup.valid"
                                        (click)="submitNewComplianceRequirement()">
                                        Save
                                    </button>
                                    <button mat-button color="primary" (click)="resetCreation()">Cancel</button>
                                </div>
                            </div>
                    </ng-template>

                    @if(creatingNewComplianceRequirement && !creatingWithPhaseID){
                        <ng-container [ngTemplateOutlet]="newComplianceRequirementStepper"></ng-container>
                    }
                    

                    
                }
            </div>
        </div>
    } @else {
        <loading-spinner></loading-spinner>
    }
</page-body>
