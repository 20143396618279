import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentUpsertDto } from "src/app/shared/generated/model/commitment-upsert-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatChipInputEvent, MatChipGrid, MatChipRow, MatChipRemove, MatChipInput } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl, NgForm, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import { map, startWith } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { MatOption } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import { MatIcon } from "@angular/material/icon";
import { MatFormField } from "@angular/material/form-field";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { NgIf, NgFor, NgClass, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { VWorkActivityDto } from "src/app/shared/generated/model/v-work-activity-dto";
import { WorkActivityService } from "src/app/shared/generated/api/work-activity.service";
import { SeasonalityTriggerComponent } from "src/app/shared/components/seasonality-trigger/seasonality-trigger.component";
import { PageBodyComponent } from "src/app/shared/components/page-body/page-body.component";

@Component({
    selector: "compliance-triggers",
    templateUrl: "./compliance-triggers.component.html",
    styleUrls: ["./compliance-triggers.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        MatFormField,
        MatChipGrid,
        NgFor,
        MatChipRow,
        MatIcon,
        MatChipRemove,
        MatInput,
        MatAutocompleteTrigger,
        MatChipInput,
        ReactiveFormsModule,
        MatAutocomplete,
        MatOption,
        NgClass,
        TinymceEditorComponent_1,
        AsyncPipe,
        MatButton,
        MatIcon,
        MatIconButton,
        MatTooltip,
        SeasonalityTriggerComponent,
        PageBodyComponent,
    ],
})
export class ComplianceTriggersComponent implements OnInit, OnDestroy {
    @ViewChild("updateCommitmentForm", { read: NgForm }) form: NgForm;
    @ViewChild("workActivityInput") workActivityInput: ElementRef<HTMLInputElement>;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    public commitment$: Observable<any>;
    public commitment: CommitmentDto;
    public currentUser: UserDto;

    public editMode: boolean = false;
    public commitmentUpsertDto: CommitmentUpsertDto;
    public originalCommitment: CommitmentUpsertDto;

    public allWorkActivities$: Observable<any>;
    public allWorkActivities: VWorkActivityDto[];
    public filteredWorkActivities$: Observable<VWorkActivityDto[]>;
    public selectedWorkActivities: VWorkActivityDto[] = [];

    removable = true;
    addOnBlur = false;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    workActivityControl = new FormControl();

    user: Subscription;

    constructor(
        private commitmentService: CommitmentService,
        private workActivityService: WorkActivityService,
        private currentCommitmentService: CurrentCommitmentService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef
    ) {
        this.filteredWorkActivities$ = this.workActivityControl.valueChanges.pipe(
            startWith(null),
            map((workActivityName: string | null) => (workActivityName ? this._filter(workActivityName) : this.allWorkActivities?.slice()))
        );
    }

    ngOnInit(): void {
        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            map((data) => {
                this.commitment = data;
                if (this.commitment) {
                    if (this.commitment.WorkActivities) {
                        this.selectedWorkActivities = JSON.parse(this.commitment.WorkActivities) || [];
                    } else {
                        this.selectedWorkActivities = [];
                    }
                    this.setForm();
                }
                return data;
            })
        );

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.allWorkActivities$ = this.workActivityService.workActivitiesGet().pipe(
            map((results) => {
                this.allWorkActivities = results;
                return results;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
    }

    setForm() {
        this.commitmentUpsertDto = this.currentCommitmentService.createCommitmentDto(this.commitment);
        this.removable = false;
        this.cdr.markForCheck();
    }

    canEdit(): boolean {
        return this.currentCommitmentService.canEditCurrentCommitment(this.currentUser, this.commitment) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.removable = true;
        this.originalCommitment = this.currentCommitmentService.createCommitmentDto(this.commitment);
        this.cdr.markForCheck();
        this.editViewEventService.editButtonClicked.next(this.editMode);
    }

    cancelEditMode() {
        this.editMode = false;
        this.selectedWorkActivities = JSON.parse(this.commitment.WorkActivities) || [];
        this.workActivityControl.setValue(null);
        this.setForm();
        this.editViewEventService.editButtonClicked.next(this.editMode);
    }

    refreshCommitment(result: CommitmentDto) {
        this.commitment = result;
        this.cdr.markForCheck();
        this.currentCommitmentService.setCurrentCommitment(this.commitment);
    }

    save() {
        this.commitmentUpsertDto.WorkActivities = JSON.stringify(this.selectedWorkActivities);
        this.commitment$ = this.commitmentService.commitmentsCommitmentIDPut(this.commitment.CommitmentID, this.commitmentUpsertDto).pipe(
            map((result) => {
                this.alertService.pushAlert(new Alert("The commitment was successfully updated.", AlertContext.Success), 5000);
                this.editMode = false;
                this.editViewEventService.editButtonClicked.next(this.editMode);
                this.refreshCommitment(result);
                return result;
            })
        );
    }

    addWorkActivity(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();

        if (value) {
            this.selectedWorkActivities.push({ Name: value });
        }

        event.chipInput!.clear();
        this.workActivityControl.setValue(null);
        this.cdr.detectChanges();
    }

    selectWorkActivity(event: MatAutocompleteSelectedEvent): void {
        this.selectedWorkActivities.push(new VWorkActivityDto({ Name: event.option.viewValue }));
        this.workActivityInput.nativeElement.value = "";
        this.workActivityControl.setValue(null);
        this.cdr.detectChanges();
    }

    removeWorkActivity(workActivities: VWorkActivityDto): void {
        const index = this.selectedWorkActivities.indexOf(workActivities);

        if (index >= 0) {
            this.selectedWorkActivities.splice(index, 1);
        }

        this.cdr.detectChanges();
    }

    canExit() {
        if (this.editMode) {
            this.commitmentUpsertDto.WorkActivities = JSON.stringify(this.selectedWorkActivities);
            return JSON.stringify(this.originalCommitment) === JSON.stringify(this.commitmentUpsertDto) && !this.workActivityControl.touched;
        } else {
            return true;
        }
    }

    private _filter(value: string): VWorkActivityDto[] {
        const filterValue = value.hasOwnProperty("Name") ? (value as any).Name.toLowerCase() : value.toLowerCase();
        return this.allWorkActivities.filter((workActivity) => workActivity.Name.toLowerCase().includes(filterValue));
    }
}
