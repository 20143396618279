<div class="compliance-requirement">
    <div class="projected-content">
        <ng-content></ng-content>
    </div>
    @if(!isEditing){
        <div class="compliance-requirement__title">
            <h2>{{ complianceRequirement().Name }}</h2>
            <div class="no-wrap">
                <button mat-icon-button color="primary" class="small-icon-button" [disabled]="disableButtons()" (click)="clickedEditButton()"><mat-icon>edit</mat-icon></button>
                @if(canDelete$ | async) {
                    <button mat-icon-button color="primary" class="small-icon-button" [disabled]="disableButtons()" (click)="clickedDeleteButton()">
                        <mat-icon>delete</mat-icon>
                    </button>
                }
            </div>
        </div>
        <div class="compliance-requirement__metadata">
            <kv-pair key="Type" [value]="complianceRequirement().ComplianceRequirementType?.Name" />
            <kv-pair key="Phase" [value]="complianceRequirement().Phase?.Name" />
        </div>
        <div class="compliance-requirement__description">
            <div class="more-details" [class.active]="viewingMoreDetails">
                <div class="grid-12">
                    <div class="g-col-12">
                        <kv-pair key="Applicable Commitment Text" [value]="applicableCommitmentText">
                            <ng-template #applicableCommitmentText>
                                <div [innerHTML]="complianceRequirement().ApplicableCommitmentText"></div>
                            </ng-template>
                        </kv-pair>
                    </div>
                    <kv-pair class="g-col-3" key="Frequency" [value]="complianceRequirement().Frequency?.Name"></kv-pair>
                    <kv-pair class="g-col-3" key="Scope" [value]="complianceRequirement().Scope?.Name"></kv-pair>
                    <kv-pair class="g-col-3" key="Compliance Lead" [value]="complianceRequirement().ComplianceLeadUser?.FullName"></kv-pair>
                    <kv-pair class="g-col-3" key="Implementation Responsiblities" [value]="implementationResponsibilities">
                        <ng-template #implementationResponsibilities>
                            @if (!complianceRequirement().ImplementationResponsibilities || complianceRequirement().ImplementationResponsibilities.length === 0) {
                                <em>None</em>
                            }
                            @for (implementationResponsibility of complianceRequirement().ImplementationResponsibilities; track $index) {
                                <div>{{ implementationResponsibility.Name }}</div>
                            }
                        </ng-template>
                    </kv-pair>
                    <kv-pair class="g-col-12" key="Tags" [value]="tagsTemplate">
                        <ng-template #tagsTemplate>
                            @if (!complianceRequirement().Tags || complianceRequirement().Tags.length === 0) {
                                <em>None</em>
                            } @else {
                                <mat-chip-set>
                                    @for (tag of tags(); track $index) {
                                        <mat-chip>{{ tag }}</mat-chip>
                                    }
                                </mat-chip-set>
                            }
                        </ng-template>
                    </kv-pair>
                </div>
            </div>
            <div class="more-details-button">
                <button mat-button type="button" (click)="viewingMoreDetails = !viewingMoreDetails" color="primary">
                    <mat-icon>{{ viewingMoreDetails ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</mat-icon> View
                    {{ viewingMoreDetails ? "Less" : "More" }} Details
                </button>
            </div>
        </div>
    } @else {
        @if(allComplianceRequirementTags$ | async; as allTags) {
            <compliance-requirement-form [editMode]="true" (cancelEditModeChange)="cancelEdit($event)" (formUpdated)="formUpdated($event)" [commitment]="complianceRequirement().Commitment" [complianceRequirement]="complianceRequirement()" [allComplianceRequirementTags]="allTags"></compliance-requirement-form>
        }
        
    }
    
</div>
