import { inject, Pipe, PipeTransform } from "@angular/core";
import { ChecklistItemDto } from "../../generated/model/checklist-item-dto";
import { ChecklistItemFilterVisiblePipe } from "src/app/shared/pipes/checklist-item-filtered/checklist-item-filtered.pipe";

@Pipe({
    name: "checklistItemVisibleCount",
    standalone: true,
})
export class ChecklistItemVisibleCountPipe implements PipeTransform {
    checklistItemFilteredPipe = inject(ChecklistItemFilterVisiblePipe);
    transform(
        value: ChecklistItemDto[],
        args: {
            phase: string[];
            scope: string[];
            frequency: string[];
            complianceRequirementType: string[];
            resourceCategory: string[];
            status: number[];
            search: string;
            speciesCode: string[];
        }
    ): number {
        const visibleItems = value.filter((item) => this.checklistItemFilteredPipe.transform(item, args));
        return visibleItems.length;
    }
}
