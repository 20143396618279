<div class="list-view" *ngIf="sourceDocument$ | async">
    <div class="g-col-12">
        <div class="table-responsive" style="height: 800px">
            <beacon-grid-actions [grid]="commitmentsGrid" downloadFileName="source-document-related-commitments" [templatePrepend]="actionPrepend">
                <button mat-flat-button *ngIf="canCreateCommitment" color="primary" (click)="navigateToCommitmentCreatePage()">
                    <mat-icon>add</mat-icon> Commitment
                </button>
                <ng-template #actionPrepend>
                    <div class="flex-start">
                        <button mat-stroked-button color="primary" style="margin-right: 1rem" (click)="downloadBulkCommitmentTemplate()">
                            <mat-icon>download</mat-icon> Download CSV Template
                        </button>

                        <div class="btn-outline">
                            <button mat-button class="btn-bar" color="primary" (click)="fileInput.click()"><mat-icon>upload</mat-icon> Bulk Upload</button>

                            <button mat-button matTooltip="Help" color="primary" (click)="bulkDownloadHelp()" tooltipPosition="after">
                                <mat-icon>help</mat-icon> Help
                            </button>
                        </div>

                        <input type="file" hidden (change)="uploadBulkCommitments(fileInput.files)" accept=".csv" #fileInput />
                    </div>
                </ng-template>
            </beacon-grid-actions>
            <ag-grid-angular
                #commitmentsGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onCommitmentsGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
