<page-body>
    <div class="detail-view" *ngIf="commitment$ | async">
        <div class="grid-12">
            <div class="g-col-8">
                <div class="flex-end">
                    <button mat-icon-button *ngIf="canEdit()" color="primary" (click)="enableEditMode()"><mat-icon>edit</mat-icon></button>
                </div>
                <ng-container *ngIf="allWorkActivities$ | async">
                    <form class="form grid-12" (ngSubmit)="save()" #updateCommitmentForm="ngForm">
                        <div class="g-col-12">
                            <esa-material-ff
                                name="Seasonality"
                                [editMode]="editMode"
                                [required]="false"
                                type="text"
                                [(ngModel)]="commitmentUpsertDto.Seasonality"
                                [customLabel]="seasonCustomLabel">
                            </esa-material-ff>
                            <ng-template #seasonCustomLabel>
                                <custom-form-label label="Seasonality" fieldDefinitionType="Seasonality"></custom-form-label>
                            </ng-template>

                            <div class="kv-pair">
                                <custom-form-label label="Work Activities" fieldDefinitionType="WorkActivities"></custom-form-label>
                                <mat-form-field appearance="outline" class="value" *ngIf="editMode">
                                    <mat-chip-grid #chipList aria-label="Work Activity selection">
                                        <mat-chip-row
                                            *ngFor="let workActivity of selectedWorkActivities"
                                            [removable]="removable"
                                            (removed)="removeWorkActivity(workActivity)">
                                            {{ workActivity.Name }}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip-row>
                                        <input
                                            matInput
                                            type="text"
                                            placeholder="Add work activity..."
                                            #workActivityInput
                                            [formControl]="workActivityControl"
                                            [matAutocomplete]="auto"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="addWorkActivity($event)" />
                                    </mat-chip-grid>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectWorkActivity($event)">
                                        <mat-option *ngFor="let workActivity of filteredWorkActivities$ | async" [value]="workActivity">
                                            {{ workActivity.Name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <div class="value-display middle-form-spacing" *ngIf="!editMode && selectedWorkActivities.length <= 0">
                                    <span>-</span>
                                </div>

                                <div class="value-display middle-form-spacing-chips" *ngIf="!editMode && selectedWorkActivities.length > 0">
                                    <mat-chip-row *ngFor="let workActivity of selectedWorkActivities">
                                        {{ workActivity.Name }}
                                    </mat-chip-row>
                                </div>
                            </div>

                            <esa-material-ff
                                name="LocationDescription"
                                [editMode]="editMode"
                                [required]="false"
                                type="text"
                                [(ngModel)]="commitmentUpsertDto.LocationDescription"
                                [customLabel]="locationCustomLabel">
                            </esa-material-ff>
                            <ng-template #locationCustomLabel>
                                <custom-form-label label="Location" fieldDefinitionType="LocationDescription"></custom-form-label>
                            </ng-template>

                            <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
                                <custom-form-label label="General Guidance" fieldDefinitionType="GeneralGuidance"></custom-form-label>
                                <div class="value" *ngIf="editMode">
                                    <div class="grid-12">
                                        <div class="g-col-12">
                                            <tinymce-editor
                                                #tinyMceEditor
                                                name="GeneralGuidance"
                                                [(content)]="commitmentUpsertDto.GeneralGuidance"></tinymce-editor>
                                        </div>
                                    </div>
                                </div>
                                <div [(innerHTML)]="commitment.GeneralGuidance" class="value-display" *ngIf="!editMode && commitment.GeneralGuidance"></div>
                                <div *ngIf="!commitment.GeneralGuidance && !editMode" class="value-display">-</div>
                            </div>
                        </div>

                        <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
                            <span [matTooltip]="!updateCommitmentForm.form.valid ? 'You are missing requried fields.' : ''">
                                <button
                                    mat-flat-button
                                    style="margin-right: 1rem"
                                    color="primary"
                                    (click)="save()"
                                    [disabled]="!updateCommitmentForm.form.valid">
                                    <mat-icon>save</mat-icon> Save
                                </button>
                            </span>

                            <button mat-button type="button" color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
                        </div>
                    </form>
                </ng-container>
            </div>
            <div class="commitment-sidebar g-col-4">
                <div class="seasonality-trigger-display-and-upsert">
                    <seasonality-trigger [commitmentID]="commitment.CommitmentID"></seasonality-trigger>
                </div>
            </div>
        </div>
    </div>
</page-body>
