<div class="detail-view" *ngIf="sourceDocument$ | async as sourceDocument">
    <div class="actions-bar">
        <button mat-icon-button *ngIf="canEdit()" color="primary" (click)="enableEditMode()"><mat-icon>edit</mat-icon></button>
    </div>

    <source-document-form
        #updateSourceDocumentForm
        [inputSource]="sourceDocumentUpsertDto"
        [projectName]="sourceDocument.Project?.Name"
        [editMode]="editMode"
        (formSubmitted)="save()"
        [showFileUpload]="true"
        (cancelEditModeChange)="cancelEdit()"></source-document-form>

    <beacon-back-to-top></beacon-back-to-top>
</div>
